import React, { useEffect } from 'react';
import pcc from "../../asset/pcc.png"
import pccc from "../../asset/pccc.png"
import plc from "../../asset/plc.png"
import mcc from "../../asset/mcc2.png"
import DB from "../../asset/DB.png"

function Card({ cardData }) {
  useEffect(() => {
    const images = [
     pcc,
     plc,
     mcc,
     DB,
     pccc,
     pccc
    ];

    document.querySelectorAll('.card-image').forEach((cardImage, index) => {
      const randomImageIndex = Math.floor(Math.random() * images.length);
      cardImage.style.backgroundImage = `url(${images[randomImageIndex]})`;
    });
  }, []);

  return (
    <div className="homecard-container">
      {cardData.map((card, index) => (
        <div className="cards" key={index}>
          <div className="card-item">
            <div className="card-image">
            </div>
            <div className="card-info">
              <h2 className="card-title">{card.title}</h2>
              <h3 className='card-title'>{card.subtitle}</h3>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Card;
