import React from 'react';
import "./about.css"

function About() {
  return (
    <div>
      <div>
        <section className="sub_header_banner about-header pr"

        >
          <div className="head_title">
            <div className="container pr">
              {/* <Fade bottom> */}
              <h1>About us</h1>
            </div>
          </div>
        </section>
        <div className='carrmacss'>
          <section class="current_openings" >
            <div class=" ">
              <div class="rcontainer pr">
                <div class="title-bar title-center" data-aos="fade-left"  >

                  {/* <Fade bottom> */}
                  <h2>About GVK </h2>
                  {/* </Fade> */}
                  <div class="dots-line"><span></span> <span></span> <span></span></div>
                </div>
                <div class="openings_txt"   >
                  {/* <Fade bottom>  */}
                  <p>
                  GVK Power has been manufacturing Low voltage, Medium voltage & Control Panels in own manufacturing set up of 5000 Sq Ft within house fabrication. Assembly & Testing Facility. We have qualified engineers & technical staft for estimation, design & manufacturing of the panel boards.                  {/* </Fade> */}
                </p>
                </div>
                <hr></hr>
              </div>
            
            </div>

          </section>

        </div>
        <div className='abouutcards'>
        <div className='aboutcard'>
        <h1>BASIC INFORMATION </h1>
        <div class="acard">
         
  {/* <img src="image.jpg" alt="Image"/> */}
  <div className='abouthead'>01.</div>
  <div class="content">
    {/* <h2>Title</h2> */}
<ul>
  <li><strong>Nature of Business: </strong>Manufacturer</li>
  <li><strong>Industry: </strong> Manufacturing, Services and Trading of Control Panels And Boards</li>
  <li><strong>Registered Address: </strong># 1138, 20th Cross Rd, A Block, Sahakar Nagar, A - Block, Bengaluru, Karnataka 560092</li>
  {/* <li><strong>GVK Warehouse </strong><br></br>#64,Doddabettahalli, Veerasagara Main Road, Vidyaranyapura (PO), Bengaluru-560097, India </li> */}
  <li><strong>Year of Establishment: </strong>2018</li> 

</ul>
    {/* <a href="#">Read more</a> */}
  </div>
</div>
        </div>
        <hr></hr>
        <div className='aboutcard'>
        <h1>INFRASTRUCTURE
 </h1>
        <div class="acard">
         
  {/* <img src="image.jpg" alt="Image"/> */}
  <div className='abouthead'>02.</div>
  <div class="content">
    {/* <h2>Title</h2> */}
<ul>
  <li><strong>Location Type: </strong>Urban</li>
  <li><strong>Size of Premises: </strong>
  Shop Floor 5000 Sq Feet</li>
  <li>Office 1200 SQ.Ft</li>
  <li>WareHouse 1200 SQ FT</li>
 
</ul>
    {/* <a href="#">Read more</a> */}
  </div>
</div>
        </div>
        </div>
        <hr></hr>
      
      </div>
    </div>
  )
}

export default About