import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./Home.css"
import Card from './Card';
import car1 from "../../asset/car1.png"
import car2 from "../../asset/car2.png"
import car3 from "../../asset/car3.png"
import kmf from "../../asset/kmf.png"
import jdn from "../../asset/jdn.png"
import amul from "../../asset/amul.png";
import hap from "../../asset/hap.png";
import iff from "../../asset/iff.png";
import iis from "../../asset/iis.png";
import jns from "../../asset/jns.png";
import ka from "../../asset/ka.png";
import ska from "../../asset/ska.png";
import ssf from "../../asset/ssf.png";
import ccd from "../../asset/ccd.png";
import mc from "../../asset/mc.png";
import ks from "../../asset/ks.png";
import gf from "../../asset/gf.png";


function Home() {
    const cardData = [
        {
          title: "Power control center",
          subtitle:"(PCC)"

        },
        {
          title: "Programmable logic controller",
          subtitle:"(PLC)"
        },
        {
            title: "Motor control center",
            subtitle:"(MCC)"
          },{
            title: "Distribution Board",
            subtitle:"(DB)"
          },{
            title: "Intelligent Motor Control Center",
            subtitle:"(IMCC)"

          },{
            title: "Power control center",
            subtitle:"(PCC)"
          },
        // Add more card data as needed
      ];
      const images = [
        'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
       'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
       'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',

        // Add more image URLs as needed
      ];
    return (
        <>
        <div className="demo-carousel">
            <Carousel
                // showArrows={true}
                autoPlay={true}
                interval={3000}
                infiniteLoop={true}
                showThumbs={false}
            >
                <div>
                    <img 
                    src={car1}
                     alt="1" />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                <img 
                    src={car2}
                     alt="1" />
                </div>
                <div>
                <img 
                    src={car3}
                     alt="1" />
                </div>
            </Carousel>
        </div>
        <div>
        <Card cardData={cardData} />
<div className='ourmanufactures'>
<h2 className="cardt">Our manufacturing range covers:</h2>


<ul>
  <li>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      className="iconpar"
    >
      <path
        fill="currentColor"
        d="M11 2v4H8l4 4 4-4h-3V2h-2zm-4 9H2v2h5v5h2v-5h5v-2h-5V6H7v5zm10 4h-2v-5h-5v-2h5V7h2v5h5v2h-5z"
      />
    </svg>
    Fixed & Draw out type Power & Motor Control center, PCC, MCC, & PMCC’s,
    Intelligent MCC’s, Bus Ducts, ACDB DCDB and Lighting DBs.
  </li>
  <li>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      className="iconpar"
    >
      <path
        fill="currentColor"
        d="M11 2v4H8l4 4 4-4h-3V2h-2zm-4 9H2v2h5v5h2v-5h5v-2h-5V6H7v5zm10 4h-2v-5h-5v-2h5V7h2v5h5v2h-5z"
      />
    </svg>
    11/33kV Medium Voltage VCB Panels with Transformer protection & RMUs.
  </li>
  <li>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      className="iconpar"
    >
      <path
        fill="currentColor"
        d="M11 2v4H8l4 4 4-4h-3V2h-2zm-4 9H2v2h5v5h2v-5h5v-2h-5V6H7v5zm10 4h-2v-5h-5v-2h5V7h2v5h5v2h-5z"
      />
    </svg>
    Control, Relay & Metering Panel. Generator Control Panels, Turbine Control
    Panels, NGR/NGT Panels.
  </li>
  <li>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      className="iconpar"
    >
      <path
        fill="currentColor"
        d="M11 2v4H8l4 4 4-4h-3V2h-2zm-4 9H2v2h5v5h2v-5h5v-2h-5V6H7v5zm10 4h-2v-5h-5v-2h5V7h2v5h5v2h-5z"
      />
    </svg>
    DG Synch, AMF & Load sharing Panels, APFC Panels, Metering Panels.PLC
    Control Panels, AC/DC Drive Panels, Control Desks.
  </li>
</ul>

<div className='clients'>
    <h2>
Clients
</h2>
<div className="image-grid">
      {/* <div className="row"> */}
        <img src= {kmf}
       
 alt="Image 1" className="image" />
            <img src= {ccd}
       
       alt="Image 1" className="image" /><img src= {ks}
       
       alt="Image 1" className="image" />
       <img src= {amul}
       
       alt="Image 1" className="image" />
        <img src= {jdn}
       
       alt="Image 1" className="image" />
         <img src= {iis}
       
       alt="Image 1" className="image" />
   
    
        {/* Repeat for the next row */}
        <img src= {iff}
       
       alt="Image 1" className="image" />

<img src= {mc}
       
       alt="Image 1" className="image" />
         <img src= {hap}
       
       alt="Image 1" className="image" />

<img src= {jns}
       
       alt="Image 1" className="image" />

<img src= {ka}
       
       alt="Image 1" className="image" />
       <img src= {ska}
       
       alt="Image 1" className="image" />

<img src= {ssf}
       
       alt="Image 1" className="image" />

<img src= {gf}
       
       alt="Image 1" className="image" />
       
    
     
    </div>
</div>
</div>

        </div>
        </>
    );
}

export default Home;
