

import React from 'react';
import "./Footer.css";
import { SocialIcon } from 'react-social-icons'


function Footer() {
  return (
    <footer className="footer">
      <div className="container row">
        <div className="footer-col">
          <h4>company</h4>
          <ul>
            <li><a href="#">about us</a></li>
            {/* <li><a href="#">our services</a></li> */}
            <li><a href="#">Electrical Control Panel Board Manufacturers and Suppliers 
            from Bangalore,India</a></li>
            <li><a href="#">Phone: +91 77605 65553/+91 98445 94600

</a></li>
  <li><a href="#">Email : sales@gvkengg.com </a></li>
          </ul>
        </div>
        <div className="footer-col">
          <h4>Address</h4>
          <ul>
            <li><a href="#">GVK POWER & ENGINEERING SOLUTIONS </a></li>
            <li><a href="#"># 1138, 20th Cross Rd, A Block, Sahakar Nagar, A - Block, Bengaluru, Karnataka 560092</a></li>
            <li><a href="#"></a></li>
            <li><a href="#"> GVK Warehouse</a></li>
            <li><a href="#">#64,Doddabettahalli, Veerasagara Main Road, Vidyaranyapura (PO), Bengaluru-560097, India </a></li>
          </ul>
        </div>
        {/* <div className="footer-col">
          <h4>online shop</h4>
          <ul>
            <li><a href="#">download</a></li>
            <li><a href="#">changelog</a></li>
            <li><a href="#">github</a></li>
            <li><a href="#">all version</a></li>
          </ul>
        </div> */}
        <div className="footer-col">
          <h4>follow us</h4>
          <div className="social-links">
          <SocialIcon url="https://www.facebook.com/GVKPowerEngineeringSolutions/"  style={{ height: 50, width: 50 }}  target='_'/>
      <SocialIcon url="https://www.instagram.com/gvk_power_and_engineering/?igshid=MmVlMjlkMTBhMg%3D%3D&utm_source=qr" style={{ height: 50, width: 50 }} />
      <SocialIcon url="https://www.linkedin.com/company/gvk-power-and-engineering-solutions/"  style={{ height: 50, width: 50 }} />
      <SocialIcon url="https://twitter.com/" style={{ height: 50, width: 50 }} />
          </div>
        </div>
         
      </div>
    </footer>
  );
}

export default Footer;
