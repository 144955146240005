import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Footer from './components/Footer/Footer';
import Panel from './components/Panel/Panel';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Panelpcc from './components/Panel/Panelpcc';
import Panelvfd from './components/Panel/Panelvfd';
import Panelpl from './components/Panel/Panelpl';
import Panelmcc from './components/Panel/Panelmcc';
import Navbar from './components/Header/Navbar';
import Paneldb from './components/Panel/Paneldb';
import Panelimcc from './components/Panel/Panelimcc';
// import Panelmcc from './components/Panel/Panelmcc';
function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        {/* <Home /> */}
        <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/About" element={<About />} />
        <Route exact path="/Contact" element={<Contact />} />
          <Route exact path="/panelb/Panel" element={<Panel />} />
          <Route exact path="/panel/Panelpcc" element={<Panelpcc />}></Route>
          <Route exact path="/panel/Panelvfd" element={<Panelvfd />}></Route>
          <Route exact path="/panel/Panelpl" element={<Panelpl />}></Route>
          <Route exact path="/panel/Panelmcc" element={<Panelmcc />}></Route>
          <Route exact path="/panel/Paneldb" element={<Paneldb />}></Route>
          <Route exact path="/panel/Panelimcc" element={<Panelimcc />}></Route>

          {/* <Route exact path="/panel/Panelimcc" element={<Panelimcc />}></Route> */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
