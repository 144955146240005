import React from 'react';
import "./contact.css";
import axios from 'axios';
import {useState,useEffect} from 'react'

function Contact() {
  const [name,setName]= useState('');
  const [emial,setEmial] = useState('');
  const [phone,setPhone] = useState('');
  const [message,setMessage]= useState('')
  const [data, setData]=useState([]);

  const handleSubmit=(e)=>{
    e.preventDefault();
    
    const data = {
      Name:name,
      Emial:emial,
      Phone:phone,
      Message:message
    }
    axios.post('https://sheet.best/api/sheets/08ea93a1-746f-4868-8207-1daf09c3a369',data).then(response=>{
      // console.log(response);
      setName('');
      setEmial('');
      setPhone('');
      setMessage('');
    })
  }

  // getting data function
  const getData=()=>{
    axios.get('https://sheet.best/api/sheets/08ea93a1-746f-4868-8207-1daf09c3a369').then((response)=>{
      setData(response.data);
    })
  }

  // triggering function
  useEffect(()=>{
    getData();
  },[data])
  return (
    <div>
   

  <body>
    <div className='contactpage'>
    <div className="container">
     
      <div className="form">
        <div className="contact-info">
          <h3 className="title">Let's get in touch</h3>
          <p className="text">
          Electrical Control Panel Board Manufacturers and Suppliers 
            from Bangalore,India
          </p>

          <div className="info">
            <div className="information">
              <i className="fas fa-map-marker-alt"></i> GVK POWER AND ENGINEERING SOLUTIONS
              
              Land mark radhakrisna kalyana mantapa Sahakarnagar, 1138, 20th Cross Rd,
 A Block, Sahakar Nagar, Bengaluru, Karnataka 560092
              <p></p>
            </div>
            <div className="information">
              <i className="fas fa-envelope"></i> Email:sales@gvkengg.com
            
            </div>
            <div className="information">
              <i className="fas fa-phone"></i>Phone : 
             
+91 77605 65553/+91 98445 94600
              {/* <p>123-456-789</p> */}
            </div>
          </div>

          {/* <div className="social-media">
            <p>Connect with us :</p>
            
            <div className="social-icons">
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div> */}
        </div>

        <div className="contact-form">
          <span className="circle one"></span>
          <span className="circle two"></span>

          <form action="index.html" autoComplete="off" onSubmit={handleSubmit}>
  <h3 className="title">Contact us</h3>
  <div className="input-container">
    <label htmlFor="name">Name</label>
    <input 
      type="text" 
      id="name" 
      name="name" 
      className="input" 
      onChange={(e) => setName(e.target.value)} 
      value={name}
    />
  </div>
  <div className="input-container">
    <label htmlFor="email">Email</label>
    <input 
      type="email" 
      id="email" 
      name="email" 
      className="input" 
      onChange={(e) => setEmial(e.target.value)} 
      value={emial}
    />
  </div>
  <div className="input-container">
    <label htmlFor="phone">Phone</label>
    <input 
      type="tel" 
      id="phone" 
      name="phone" 
      className="input" 
      onChange={(e) => setPhone(e.target.value)} 
      value={phone}
    />
  </div>
  <div className="input-container textarea">
    <label htmlFor="message">Message</label>
    <textarea 
      id="message" 
      name="message" 
      className="input" 
      onChange={(e) => setMessage(e.target.value)} 
      value={message}
    ></textarea>
  </div>
  <button type="submit" className="btn">SUBMIT</button>
</form>

        </div>
      </div>
    </div>
    </div>
 </body>

    </div>
  )
}

export default Contact