import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../asset/logo.jpeg';

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClick = () => {
    // Close the menu when a link is clicked
    setMenuOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleMenuToggle = () => {
    // Toggle the menu open/close state
    setMenuOpen(!menuOpen);
  };

  return (
    <div>
      <header className="header">
        <div className="container">
          <Link to="/" className="logo" onClick={handleClick}>
            <img src={logo} className="logo" alt="Logo" />
          </Link>
          <input
            className="menu-btn"
            type="checkbox"
            id="menu-btn"
            checked={menuOpen}
            onChange={handleMenuToggle}
          />
          <label className="menu-icon" htmlFor="menu-btn">
            <span className="navicon"></span>
          </label>
          <ul className={`menu ${menuOpen ? 'open' : ''}`}>
            <li>
              <Link to="/" activeClassName="active" onClick={handleClick}>
                HOME
              </Link>
            </li>
            <li>
              <Link to="/about" activeClassName="active" onClick={handleClick}>
                ABOUT US
              </Link>
            </li>
            <li>
              <Link to="/panelb/Panel" activeClassName="active" onClick={handleClick}>
                PRODUCTS
              </Link>
            </li>
            <li>
              <Link to="/contact" activeClassName="active" onClick={handleClick}>
                CONTACT
              </Link>
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
}
